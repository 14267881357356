<template>
  <v-sheet flat class="pa-1 mt-3">
    <v-row dense>
      <v-col class="d-flex justify-end warning--text"
        ><div>Signed in as admin, will not enforce saving all 8 layers.</div>
        <v-checkbox
          v-model="disableAdminMode"
          label="Disable Admin Mode"
          color="warning"
          class="title admin-label ml-5 mt-n1"
          @change="updateAdminMode"
        ></v-checkbox>
      </v-col>
    </v-row>
  </v-sheet>
</template>
<script>
export default {
  name: "AdminModeToggle",
  data() {
    return {
      disableAdminMode: false,
    };
  },
  methods: {
    updateAdminMode() {
      this.$emit("updateAdminMode", this.disableAdminMode);
    },
  },
};
</script>
<style scoped>
.admin-label /deep/ label {
  color: #950952 !important;
}
</style>
